import React from 'react'
import starIcon from '../../../assets/images/star-icon.png'
import howItWork from '../../../assets/images/how-its-work.png'

const WhyChooseUs = () => {
    return (
        <section className="how-its-work-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-content">
                            <span className="sub-title">
                                <img src={starIcon} alt="banner" /> 
                                Clienții Ne Apreciază
                            </span>
                            <h2>De ce să ne alegi?</h2>
                            <p>Avem o echipă de profesioniști cu înaltă calificare și experiență, care au cunoștințe în diverse domenii IT.</p>
                            <div className="inner-box">
                                <div className="single-item">
                                    <div className="count-box">
                                        1
                                    </div>
                                    <h3>Soluții complete</h3>
                                    <p>Dezvoltăm o gamă largă de sisteme IT, pentru diverse industrii inclusiv logistică, transport, comerț, banking, producție industrială,  și sectorul energetic.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        2
                                    </div>
                                    <h3>Flexibilitate și personalizare</h3>
                                    <p>Adaptăm soluțiile pentru a satisface nevoile și obiectivele specifice fiecărui client. Astfel aplicții oferite sunt personalizate și eficiente.</p>
                                </div>
                                <div className="single-item">
                                    <div className="count-box">
                                        3
                                    </div>
                                    <h3>Succes dovedit</h3>
                                    <p>Avem o istorie de a livra cu succes proiecte IT pentru o varietate de clienți, care demonstrează capacitatea de a oferi rezultate.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="how-its-work-image">
                            <img src={howItWork} alt="banner" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs